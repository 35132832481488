import * as joy from "@mui/joy";
import { OmitKnown } from "@nna/core";

import { Radio, RadioProps, RadioValue } from "./Radio";

/** The radio to appear in {@link RadioGroup} */
export type RadioGroupRadio<V extends RadioValue> = OmitKnown<
	RadioProps<V>,
	"checked" | "defaultValue" | "name"
> &
	Required<Pick<RadioProps<V>, "label" | "value">>;

/** Props for {@link RadioGroup} */
export interface RadioGroupProps<V extends RadioValue>
	extends OmitKnown<
			joy.RadioGroupProps,
			"children" | "defaultValue" | "value"
		>,
		Pick<RadioProps<V>, "defaultValue" | "value"> {
	/** The radios to show in the {@link RadioGroup} */
	radios: ReadonlyArray<RadioGroupRadio<V>>;
}

/** A radio group component that regroups other radio */
export function RadioGroup<V extends RadioValue>(props: RadioGroupProps<V>) {
	const { radios, ...groupProps } = props;

	return (
		<joy.RadioGroup
			data-testid="ui/radio-group"
			orientation="horizontal"
			{...groupProps}
		>
			{radios.map(({ value, ...props }) => (
				<Radio
					data-testid={`ui/radio-group/${value}`}
					id={`radio-group/radio/${value}`}
					{...props}
					key={value}
					value={value}
				/>
			))}
		</joy.RadioGroup>
	);
}
