import { OmitKnown } from "@nna/core";
import { useField } from "formik";
import { ReactNode } from "react";
import { PickDeep } from "type-fest";

import { DotPath } from "../../../../utils/types/DotPath";
import { RadioValue } from "../Radio";
import { RadioGroup, RadioGroupProps } from "../RadioGroup";

/** Props for {@link RadioGroupField} */
export interface RadioGroupFieldProps<V extends RadioValue, N extends string>
	extends OmitKnown<RadioGroupProps<V>, "name" | "onBlur" | "onChange"> {
	/** Name of the input (for formik) */
	name: N;
}

/** Wraps {@link RadioGroup} for a formik use */
// @ts-expect-error -- With generic template, `PickDeep` is infinite
//	(but does not occur when the parameters are set)
export function RadioGroupField<V extends RadioValue>(
	props: RadioGroupFieldProps<V, string>,
): ReactNode;
/**
 * Wraps {@link RadioGroup} for a formik use.
 *
 * This function-override constraint the name and the values
 * 	to set from a given object.
 *
 * @example
 * // `value` is determined by the object and the path (=> "1" or "2")
 * <RadioGroupField<{a:{b: "1" | "2"}}, "a.b"> value="1" />
 */
export function RadioGroupField<T extends object, const N extends DotPath<T>>(
	// This function definition **must** be after
	//	=> the `string` constraint with a single template parameter is "stronger" that this one
	props: RadioGroupFieldProps<
		Extract<PickDeep<T, N>[keyof PickDeep<T, N>], RadioValue>,
		N
	>,
): ReactNode;
/** Wraps {@link RadioGroup} for a formik use */
export function RadioGroupField(
	props: RadioGroupFieldProps<RadioValue, string>,
) {
	const { name } = props;

	const [{ onBlur, onChange, value }] = useField<RadioValue>(name);
	return (
		<RadioGroup
			{...props}
			onBlur={onBlur}
			onChange={onChange}
			value={value}
		/>
	);
}
