import styled from "@emotion/styled";
import * as joy from "@mui/joy";

import { theme } from "../../../ui-layout/styles/theme";

// This might also be used for a `SelectOption`

/**
 * The option wrapper to use in all components rendering an options list
 * (could be an sx prop in the Autocomplete component, later)
 */
export const AutocompleteOption = styled(joy.AutocompleteOption)`
	cursor: pointer !important;
	padding: 8px 8px 8px 12px;

	:hover {
		background-color: ${theme.palette.grey[10]};
	}

	:not(:last-child) {
		border-bottom: 1px solid ${theme.palette.grey[40]};
	}
`;
