import { InputProps } from "./Input";
import { Formatters } from "../../../../utils/formatters";
import { isReadonlyArray } from "../input.date-iso.utils";

/** Content (of an input) that can be formatted */
export type InputValueData = Pick<InputProps, "type" | "value">;

/**
 * A default formatter that format a value (generally from an input)
 * to the "most expected" format.
 *
 * @param formatters to format with i18n in some cases
 * @param toFormat the content with the value to format
 * @returns the formatted value (generally in string)
 */
export function formatInputValue(
	formatters: Formatters,
	toFormat: InputValueData,
): number | string {
	const { type, value } = toFormat;

	if (isReadonlyArray(value)) {
		// Arrays are not really managed
		return formatInputValue(formatters, {
			...toFormat,
			value: value.join(", "),
		});
	}
	if (value === undefined) {
		return "";
	}

	const { fCurrency, fDate } = formatters;
	if (type === "date-iso") {
		return value ? fDate(value) : "";
	}
	if (type === "number-currency" && value !== "") {
		const currency = +value;
		return Number.isNaN(currency) ? "-" : fCurrency(currency);
	}

	return value;
}
