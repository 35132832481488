import { OmitKnown } from "@nna/core";
import { useField, useFormikContext } from "formik";
import { useTranslation } from "next-i18next";

import { FocusInput, FocusInputProps } from "./FocusInput";
import { DotPath } from "../../../../utils/types/DotPath";
import { InputPropsType } from "../inputs/Input";

/** Props for {@link FieldInput} */
export interface FieldInputProps<T extends object | string>
	extends OmitKnown<FocusInputProps, "asHeader" | "input"> {
	/** Override props for the input when focused */
	input?: OmitKnown<
		NonNullable<FocusInputProps["input"]>,
		"error" | "name" | "onChange" | "tabIndex" | "type" | "value"
	>;
	/** Name of the input */
	name: T extends object ? DotPath<T> : T;
	/** Type of the input */
	type?: InputPropsType;
}
/**
 * Wraps {@link FocusInput} in a Formik {@link Field}.
 *
 * Note:
 * - It automatically adds a default `endDecorator` on type `currency`
 *
 * @template T the full object this field validates (preferred) or a simple string
 */
export function FieldInput<T extends object | string>(
	props: FieldInputProps<T>,
) {
	const { input: inputProps = {}, name, type, ...cellProps } = props;

	const { t } = useTranslation();
	const [field, meta] = useField(name);
	// When the form is submitted and the input has not been touched
	//	Case: required input
	const hasBeenSubmitted = 0 < useFormikContext().submitCount;

	if (type === "number-currency" && !inputProps.endDecorator) {
		return (
			<FieldInput
				{...props}
				input={{
					endDecorator: t("common.currency.chf"),
					...inputProps,
				}}
			/>
		);
	}

	return (
		<FocusInput
			{...cellProps}
			input={{
				type,
				...inputProps,

				...field,
				error: meta.error &&
					(hasBeenSubmitted || meta.touched) && {
						title: meta.error,
					},
			}}
		/>
	);
}
