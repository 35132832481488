import styled from "@emotion/styled";
import { TooltipProps } from "@mui/joy";
import { Falsy, OmitKnown } from "@nna/core";

import { Input, InputProps } from "./Input";
import { Tooltips } from "../../../ui-atoms/components";
import { Layout } from "../../../ui-layout";

const DecoratorsWrapper = styled(Layout.Lined)`
	gap: 4px;
`;

/** "Props" for the tooltip in {@link InlinedError} */
export type InlinedErrorTooltip = Pick<TooltipProps, "placement" | "title">;

/** Props for {@link InlinedError} */
export interface InlinedErrorProps extends OmitKnown<InputProps, "error"> {
	/** The error to show in the tooltip */
	error?: Falsy | InlinedErrorTooltip;
	/**
	 * Set the position of the "tooltip-error"
	 * when a `endDecorator` is also set.
	 *
	 * @default "start"
	 */
	errorPosition?: "end" | "start";
}
/** Input with its error description inside the tooltip (itself inside the input). */
export function InlinedError(props: InlinedErrorProps) {
	const {
		endDecorator,
		error,
		errorPosition = "start",
		...inputProps
	} = props;

	return (
		<Input
			{...inputProps}
			endDecorator={
				error ? (
					<DecoratorsWrapper reverse={errorPosition === "end"}>
						<Tooltips.Error {...error} />

						{endDecorator}
					</DecoratorsWrapper>
				) : (
					endDecorator
				)
			}
			error={!!error}
		/>
	);
}
