import * as joy from "@mui/joy";
import { ModelBase, QueryResults } from "@nna/core";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useTranslation } from "next-i18next";
import { useCallback, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";

import { AutoComplete, AutocompleteProps } from "../AutoComplete";

/**
 * Function that returns a {@link UseQueryOptions} depending on the search string
 * 	(and the already loaded options)
 */
export type ModelSelectQueryOptions<T extends ModelBase> = (
	search: string,
	selected: ReadonlyArray<T["_id"]>,
) => UseQueryOptions<unknown, unknown, QueryResults<T>>;

/** Function that returns a {@link UseQueryOptions} depending on selected ids */
export type ModelSelectQueryValues<T extends ModelBase> = (
	selected: ReadonlyArray<T["_id"]>,
) => UseQueryOptions<unknown, unknown, QueryResults<T>>;

/** Props for {@link ModelSelect} */
export interface ModelSelectProps<
	T extends ModelBase,
	Multiple extends boolean | undefined = false,
	DisableClearable extends boolean | undefined = false,
> extends Pick<
		AutocompleteProps<T, Multiple, DisableClearable, false>,
		| "$applyCellStyles"
		| "autoFocus"
		| "className"
		| "defaultValue"
		| "disableClearable"
		| "endDecorator"
		| "error"
		| "getOptionDisabled"
		| "getOptionLabel"
		| "limitTags"
		| "loadingText"
		| "multiple"
		| "name"
		| "noOptionsText"
		| "onBlur"
		| "onChange"
		| "placeholder"
		| "renderOption"
		| "startDecorator"
		| "sx"
	> {
	/** Time for the debounce query */
	debounceTime?: number;
	/** Get {@link UseQueryOptions} to load (and search) models */
	queryOptions: ModelSelectQueryOptions<T>;
	/**
	 * Load the selected values
	 *
	 * This is done in the component for:
	 * 	1. Be able to use only the id(s) from {@link ModelSelectProps.value} (eg: ids from the query-path)
	 * 	2. Avoid Joy warning message
	 * 	3. Avoid to load the values outside this component
	 */
	queryValues: ModelSelectQueryValues<T>;
	/** The value (or at least the id) to select */
	value?: joy.AutocompleteProps<
		{ _id: T["_id"] },
		Multiple,
		DisableClearable,
		false
	>["value"];
}

/**
 * Input for any {@link ModelAny} (or transformed data that match the model) search & select
 * Build around {@link Autocomplete}
 */
export function ModelSelect<
	T extends ModelBase,
	Multiple extends boolean = false,
	DisableClearable extends boolean = false,
>(props: ModelSelectProps<T, Multiple, DisableClearable>) {
	const {
		debounceTime = 250,
		multiple,
		queryOptions: querySearch,
		queryValues,
		value: valueIn,
		...joyProps
	} = props;

	const { t } = useTranslation();

	// Ids of the current selected values
	const ids = useMemo(() => {
		const values = valueIn
			? multiple
				? (valueIn as ModelBase[])
				: [valueIn as ModelBase]
			: [];

		return values.map(({ _id }) => _id);
	}, [multiple, valueIn]);

	// Query to get selected values
	const { data: dataValues } = useQuery(
		useMemo(() => {
			const query = queryValues(ids);
			return { ...query, enabled: !!ids.length } satisfies typeof query;
		}, [queryValues, ids]),
	);
	const valuesRaw = useMemo(
		// Slice for the re-used cache of react-query
		() => (dataValues?.data ?? []).slice(0, ids.length),
		[dataValues?.data, ids.length],
	);

	// Transform selected values for the Joy autocomplete prop
	const valueOut = useMemo(() => {
		type ValueOut<M extends boolean> = joy.AutocompleteProps<
			T,
			M,
			false,
			false
		>["value"];

		if (multiple) {
			return valuesRaw satisfies ValueOut<true>;
		}

		return (
			valuesRaw.length ? valuesRaw[0] : null
		) satisfies ValueOut<false>;
	}, [valuesRaw, multiple]);

	// Search values to filter the options (via a query)
	const [search, setSearch] = useState("");
	const [$search] = useDebounce(search, debounceTime);

	// Query to get options with search filter
	const { data, isLoading } = useQuery(
		useMemo(() => querySearch($search, ids), [querySearch, $search, ids]),
	);
	const optionsRaw = useMemo(() => data?.data ?? [], [data?.data]);

	// Add the values with the options to remove the annoying JOY error "a value not in options"
	const options = useMemo(
		() => [...optionsRaw, ...valuesRaw],
		[optionsRaw, valuesRaw],
	);
	// But removed in the view
	const filterOptions = useCallback<
		NonNullable<joy.AutocompleteProps<T, true, true, true>["filterOptions"]>
	>(options => options.slice(0, optionsRaw.length), [optionsRaw.length]);

	// Request loading or currently typing (search not applied yet)
	const loading = !!(isLoading || search !== $search);
	return (
		<AutoComplete
			data-testid="ui/model-select"
			id="model-select/search-input"
			limitTags={3}
			loadingText={t("common.state.searching")}
			noOptionsText={t("common.state.no-results")}
			placeholder={t("common.action.search")}
			selectOnFocus={true}
			{...joyProps}
			filterOptions={filterOptions}
			freeSolo={false}
			getOptionKey={({ _id }) => _id}
			inputValue={search}
			isOptionEqualToValue={({ _id: a }, { _id: b }) => a === b}
			loading={loading}
			multiple={multiple}
			onInputChange={(_, changed) => setSearch(changed)}
			options={options}
			value={valueOut as never}
		/>
	);
}
