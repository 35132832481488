import styled from "@emotion/styled";
import * as joy from "@mui/joy";
import { OmitKnown } from "@nna/core";

import { Icon } from "../../ui-atoms/components/Icons/Icon";
import { textBody } from "../../ui-layout/styles/textStyles";
import { theme } from "../../ui-layout/styles/theme";

/** Possible value for a {@link Radio} */
export type RadioValue = number | string;

/** Props for {@link Radio} */
export interface RadioProps<V extends RadioValue>
	extends OmitKnown<joy.RadioProps, "defaultValue" | "value"> {
	/** Default value for this radio. */
	defaultValue?: V;
	/** Value for this radio. */
	value?: V;
}

/** Radio component with default values */
export function Radio<V extends RadioValue>(props: RadioProps<V>) {
	return (
		<RadioStyled
			checkedIcon={<Icon name="checkmark" size={18} />}
			data-testid="ui/radio"
			{...props}
		/>
	);
}

const { radioClasses } = joy;
const RadioStyled = styled(joy.Radio)<Pick<joy.RadioProps, "disabled">>`
	margin-block-start: 0;

	// Unchecked
	& .${radioClasses.radio} {
		background-color: ${theme.vars.palette.grey[30]};
		border: none;

		:hover {
			background-color: ${theme.vars.palette.grey[30]};
		}
	}
	// Checked
	& .${radioClasses.radio}.${radioClasses.checked} {
		background-color: ${theme.vars.palette.blue.primary};
		border: none;
		color: ${theme.vars.palette.white.primary};
	}
	// Disabled
	& .${radioClasses.disabled} .${radioClasses.input} {
		cursor: not-allowed !important;
		pointer-events: all !important;
	}
	// Disabled - unchecked
	& .${radioClasses.disabled} {
		background-color: ${theme.vars.palette.grey[45]};

		:hover {
			background-color: ${theme.vars.palette.grey[45]};
		}
	}
	// Disabled - checked
	& .${radioClasses.disabled}.${radioClasses.checked} {
		background-color: ${theme.vars.palette.grey[45]};
		color: white;
	}
	// Label
	& .${radioClasses.label} {
		color: ${({ disabled }) =>
			disabled
				? theme.vars.palette.grey[45]
				: theme.vars.palette.grey[90]};
		${textBody}
	}
`;
