import { z } from "zod";

/** The possible values for a country */
export const COUNTRY_VALUES = ["suisse", ""] as const;

/** Country values from type */
export type CountryValue = (typeof COUNTRY_VALUES)[number];

/** The validation schema for {@link CountryValue} */
export const countryValue = z.enum(
	COUNTRY_VALUES,
) satisfies z.ZodType<CountryValue>;
