import { useField } from "formik";
import { TFunction, useTranslation } from "next-i18next";
import { countryValue, CountryValue } from "~/common/people/country";

import { FormTable } from "../../../ui-atoms/components";
import {
	AutoComplete,
	autoCompleteCellSX,
	AutocompleteOption,
	AutocompleteProps,
} from "../AutoComplete";

/** Props for {@link CountrySelectField} */
export type CountrySelectFieldProps = Pick<
	AutocompleteProps<never, false, false, false>,
	"name" | "placeholder"
>;

/**
 * Generic cell field for {@link CountryValue}, to be used in any TableForm
 * Wraps a {@link AutoComplete} around a {@link useField} for formik
 */
export function CountrySelectField(props: CountrySelectFieldProps) {
	const { t } = useTranslation();
	const { name, placeholder } = props;

	const [{ onBlur, onChange, value }, { error }] = useField<CountryValue>(
		name ?? "",
	);

	if (!name) {
		return;
	}

	return (
		<FormTable.FocusableTableCell
			whenFocused={
				<AutoComplete
					$applyCellStyles
					autoFocus
					data-testid="generic-field/country"
					defaultValue={countryValue.Values["suisse"]}
					error={!!error}
					name={name}
					onBlur={onBlur}
					onChange={(event, value) =>
						onChange({
							...event,
							target: {
								...event.target,
								name,
								value: value,
							},
						})
					}
					options={countryValue.options}
					placeholder={placeholder}
					renderOption={(props, option) => (
						<AutocompleteOption {...props} key={option}>
							{getLabel(t, option)}
						</AutocompleteOption>
					)}
					sx={autoCompleteCellSX}
					value={value}
				/>
			}
		>
			<FormTable.FormCellInnerWrapper>
				<FormTable.UnfocusedGenericValue placeholder={placeholder}>
					{getLabel(t, value)}
				</FormTable.UnfocusedGenericValue>
			</FormTable.FormCellInnerWrapper>
		</FormTable.FocusableTableCell>
	);
}

function getLabel(t: TFunction, option?: CountryValue | null) {
	switch (option) {
		case "suisse":
			return t("entity.people.address.country_type.suisse");
		default:
			return option;
	}
}
