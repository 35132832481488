import { useField, useFormikContext } from "formik";
import React from "react";
import { PickDeep } from "type-fest";

import { DotPath } from "../../../../utils/types/DotPath";
import { FormTable, Tooltips } from "../../../ui-atoms/components";
import {
	RadioGroupField,
	RadioGroupFieldProps,
} from "../Formik/RadioGroupField";
import { RadioValue } from "../Radio";

/**
 * Wraps {@link FocusInput} in a Formik {@link Field}.
 *
 * Note:
 * - It automatically adds a default `endDecorator` on type `currency`
 *
 * @template T the full object this field validates (preferred) or a simple string
 */
export function RadioGroupFieldCell<
	T extends object,
	const N extends DotPath<T>,
>(
	props: RadioGroupFieldProps<
		Extract<PickDeep<T, N>[keyof PickDeep<T, N>], RadioValue>,
		N
	>,
) {
	const { name } = props;

	const [, meta] = useField(name);
	// When the form is submitted and the input has not been touched
	//	Case: required input
	const hasBeenSubmitted = 0 < useFormikContext().submitCount;
	const error = meta.error &&
		(hasBeenSubmitted || meta.touched) && {
			title: meta.error,
		};

	return (
		<FormTable.UnfocusableTableCell error={!!error}>
			<RadioGroupField<T, N> {...props} />

			{error ? <Tooltips.Error {...error} /> : null}
		</FormTable.UnfocusableTableCell>
	);
}
