import * as dateFns from "date-fns";
import { useTranslation } from "next-i18next";

import { Inputs } from "..";
import {
	UnfocusedGenericValue,
	UnfocusedGenericValueProps,
} from "../../../ui-atoms/components/form-table";

const dataTestid = "cell-input/unfocused-input-retirement";

/** Props for {@link UnfocusedInputValueWithRetirementAge} */
interface UnfocusedInputValueWithRetirementAgeProps
	extends UnfocusedGenericValueProps {
	/** The birthday of the customer */
	birthday: Date;
	/** The current value of the input (should be a date) */
	value: Inputs.InlinedErrorProps["value"];
}

/** An unfocused cell for date inputs with retirement date + age */
export function UnfocusedInputValueWithRetirementAge(
	props: UnfocusedInputValueWithRetirementAgeProps,
) {
	const { t } = useTranslation();
	const { birthday, value } = props;

	const formattedValue =
		value && typeof value === "string"
			? t("common.date.birthday.date-with-age", {
					age: dateFns.differenceInYears(new Date(value), birthday),
					date: dateFns.format(value, t("common.format.date")),
				})
			: value;

	return (
		<UnfocusedGenericValue data-testid={dataTestid} {...props}>
			{formattedValue}
		</UnfocusedGenericValue>
	);
}
