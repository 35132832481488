import * as dateFns from "date-fns";
import { useTranslation } from "next-i18next";

export type FormatCurrencyOptions = Pick<
	Intl.NumberFormatOptions,
	"maximumFractionDigits" | "minimumFractionDigits"
>;
function formatCurrency(
	locale: string,
	number: number,
	options: FormatCurrencyOptions = {},
): string {
	return number.toLocaleString(locale, {
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,
		...options,
	});
}

/**
 * Hook returning formatters depending on the current translation.
 *
 * @example ```tsx
 * const { fDate } = useFormatters();
 * const now = new Date();
 * return <span>{fDate(now)}</span>;
 * ```
 *
 * @returns an object with different formatter functions
 */
export function useFormatters() {
	const { t } = useTranslation();

	const fCurrency = formatCurrency.bind(
		formatCurrency,
		t("common.format.locale-currency"),
	);
	const fCurrencyWithSign: typeof fCurrency = (...params) =>
		`${fCurrency(...params)} ${t("common.currency.chf")}`;

	return {
		/** Formats a currency number (the currency sign is not included). */
		fCurrency,
		/** Formats a currency number (with the currency sign). */
		fCurrencyWithSign,
		/**
		 * Formats a date
		 *
		 * @param date date-like to format
		 * @returns the date formated
		 */
		fDate: (date: dateFns.DateArg<Date>) =>
			dateFns.format(date, t("common.format.date")),
	};
}
/** The formatters output from {@link useFormatters} */
export type Formatters = ReturnType<typeof useFormatters>;
