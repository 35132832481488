import { ChangeEvent } from "react";

import type { InputProps } from "./inputs/Input";
import { InputUtils } from "../../../utils";

// Note: this is not really a good thing,
//	but it is easy and separated enough to not "break" the regular input,
//	while still allowing a simpler way to use it with formik

/**
 * Transforms a "normal" input change event to replace the value of the event by its ISO format.
 *
 * This allows a simpler validation with zod schema (when used with `ObjectForJson`)
 *
 * @param event to transform
 * @returns event with the value transformed to ISO string
 */
export function transformEventToDateISO(
	event: ChangeEvent<HTMLInputElement>,
): ChangeEvent<HTMLInputElement> {
	return InputUtils.transformChangeEvent(event, (_, event) => {
		const date = event.target.valueAsDate;
		return date ? date.toISOString() : "";
	});
}

/** For type compatibility (readonly array) */
export const isReadonlyArray = Array.isArray as (
	value: unknown,
) => value is readonly unknown[];

/**
 * Transforms a "date-iso" value for an input to be HTML compatible (and formik).
 * The value can even be a Date or null, but the <Input /> does not specify it.
 *
 * This allows a simpler validation with zod schema (when used with `ObjectForJson`)
 *
 * @param value to transform
 * @returns transformed value
 */
export function transformValueToDateISO(
	value: Date | InputProps["value"] | null,
): InputProps["value"] {
	if (value === null) {
		return "";
	}
	if (!value || isReadonlyArray(value)) {
		return value;
	}

	return (value instanceof Date ? value : new Date(value))
		.toISOString()
		.slice(0, 10);
}
