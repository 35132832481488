import { Inputs } from "..";
import { useFormatters } from "../../../../utils/formatters";
import { FormTable } from "../../../ui-atoms/components";
import { formatInputValue } from "../inputs";
import { InputProps } from "../inputs/Input";

/** Props for {@link UnfocusedInputValue} */
export interface UnfocusedInputValueProps
	extends Pick<InputProps, "type">,
		Pick<
			FormTable.UnfocusedGenericValueProps,
			"disabled" | "endDecorator" | "placeholder" | "startDecorator"
		> {
	/** The current value of the input */
	value: Inputs.InlinedErrorProps["value"];
}

/** Default render for {@link FocusInputProps.unfocusedValue} */
export function UnfocusedInputValue(props: UnfocusedInputValueProps) {
	const formatters = useFormatters();

	return (
		<FormTable.UnfocusedGenericValue
			{...props}
			data-testid="cell-input/unfocused-input-value"
		>
			{formatInputValue(formatters, props)}
		</FormTable.UnfocusedGenericValue>
	);
}
