import { useCallback, useState } from "react";

import { Input, InputProps } from "./Input";
import { Icon } from "../../../ui-atoms/components/Icons/Icon";
import { Button, ButtonVariants } from "../Button/Button";

/** Props for {@link Password} */
export type PasswordProps = Omit<InputProps, "endDecorator" | "type">;

/** Regular {@link Input} with a "show-password" icon */
export function Password(props: PasswordProps): JSX.Element {
	const [showPassword, setShowPassword] = useState(false);

	const ShowPassword = useCallback(
		() => (
			<Button
				onClick={() => setShowPassword(!showPassword)}
				startDecorator={
					<Icon name={showPassword ? "viewOff" : "view"} />
				}
				type="button"
				variant={ButtonVariants.TEXT}
			/>
		),
		[showPassword, setShowPassword],
	);

	return (
		<Input
			{...props}
			endDecorator={<ShowPassword />}
			type={showPassword ? "text" : "password"}
		/>
	);
}
