import styled from "@emotion/styled";
import * as joy from "@mui/joy";

import { Input } from "./Input";
import { theme } from "../../../ui-layout/styles/theme";

/**
 * Styles overrides when we want to display the {@link Input} inside a form table cell
 * In Joy SX format
 */
export const inputCell = {
	borderRadius: "0",
	height: "100%",
	minHeight: "100%",
	outline: "none",
	paddingLeft: "20px",

	[`&.${joy.inputClasses.focused}`]: {
		border: `2px solid ${theme.palette.blue.primary}`,
		borderRadius: "0",
		outline: "none",
	},
};

/**
 * Styles overrides when we want to display a small {@link Input} (e.g. in {@link Pagination})
 */
export const InputSmall = styled(Input)`
	border-radius: 4px;
	height: 24px;
	min-height: 24px;
	min-width: 54px;
	padding: 3px 8px;
	width: 54px;
`;
